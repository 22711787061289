@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.anchor {
	position: relative;
	top: calc((var(--header-height, 0px) + 1.5rem) * -1);
	pointer-events: none;

	// VARIANTs
	&--full-section {
		position: absolute;
		bottom: calc((var(--header-height, 0px)));
	}

	// MQs
	@media ($mdUp) {
		top: calc((var(--header-height, 0px) + 2rem) * -1);
	}
}
