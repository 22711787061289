@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.section {
	margin-bottom: 3.75rem;

	// VARIANTs
	&--cdbg {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 50%;
			left: 0;
			z-index: -1;
			height: 1000vh;
			background-color: $white;
			pointer-events: none;

            :global(.contextual-modal) & {
                background-color: transparent;
            }
		}
	}
	&--bgTopHalf {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 50%;
			left: 0;
			z-index: -1;
			height: 50%;
			background-color: $white;
			pointer-events: none;
		}
	}
	&--bgBottomHalf {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;
			z-index: -1;
			height: 50%;
			background-color: $white;
			pointer-events: none;
		}
	}

	// MQs
	@media ($xlUp) {
		margin-bottom: 5.75rem;
	}
}
