@mixin image-gradient() {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background-image: $gradient-image;
}

@mixin image-horizontal-gradient() {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background-image: $gradient-horizontal-image;
}
