%link-mask {
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&:focus {
		box-shadow: none;
		&::before {
			box-shadow: $btn-focus-box-shadow;
		}
	}
	&:focus:not(:focus-visible) {
		&::before {
			box-shadow: none;
		}
	}
}
