@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.hideTabletMobile {
    @media ($mdDown) {
        display: none;
    }
}

.hideDesktopMobile {
    @media ($smDown), ($mdUp) {
        display: none;
    }
}

.hideTabletDesktop {
    @media ($smUp) {
        display: none;
    }
}
