/* stylelint-disable */
.mb-last-0 > :last-child {
    margin-bottom: 0 !important;
}
.text-ls {
    letter-spacing: $letter-spacing !important;
}
.text-ls-normal {
    letter-spacing: normal !important;
}
.link-mask {
    @extend %link-mask;
}
.zindex-1 {
    z-index: 1 !important;
}
.zindex-10 {
    z-index: 10 !important;
}
.minw-0 {
    min-width: 0 !important;
}
.minw-auto {
    min-width: auto !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
// More specificity needed
.border-grey-medium.border-grey-medium {
    border-color: $grey-medium !important;
}
.border-grey-dark.border-grey-dark {
    border-color: $grey-dark !important;
}
.border-grey-light.border-grey-light {
    border-color: $grey-light !important;
}
.d-contents {
    display: contents !important;
}
.top-header-height {
    top: var(--header-height, 0) !important;
}
.link-inherit {
    color: inherit;
    text-decoration: underline;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
.text-prewrap {
  white-space: pre-wrap !important;
}

$utilities: map-merge(
    $utilities,
    (
        'align': null,
        'float': null,
        // 'opacity': null,
        // 'overflow': null,
        // 'display': null,
        'shadow': null,
        // 'position': null,
        // 'top': null,
        // 'bottom': null,
        // 'start': null,
        // 'end': null,
        // 'translate-middle': null,
        'border': null,
        'border-top': (
            responsive: true,
            property: border-top,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        'border-end': (
            responsive: true,
            property: border-right,
            class: border-end,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        'border-bottom': (
            responsive: true,
            property: border-bottom,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        'border-start': (
            responsive: true,
            property: border-left,
            class: border-start,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        'border-color': null,
        'border-width': null,
        'width': (
            property: width,
            responsive: true,
            class: w,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%,
                auto: auto,
            ),
        ),
        // 'max-width': null,
        'viewport-width':
            (
                property: width,
                class: vw,
                values: (
                    100: 100vw,
                ),
            ),
        'min-viewport-width': null,
        'height': (
            property: height,
            responsive: true,
            class: h,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%,
                auto: auto,
            ),
        ),
        'min-height': (
            property: min-height,
            responsive: true,
            class: minh,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%,
                auto: auto,
            ),
        ),
        'max-height': null,
        'viewport-height': (
            property: height,
            class: vh,
            values: (
                100: 100vh,
            ),
        ),
        'min-viewport-height': null,
        'flex': null,
        // 'flex-direction': null,
        // 'flex-grow': null,
        // 'flex-shrink': null,
        // 'flex-wrap': null,
        // 'gap': null,
        // 'justify-content': null,
        // 'align-items': null,
        'align-content': null,
        // 'align-self': null,
        'order': (
            responsive: true,
            property: order,
            values: (
                first: -1,
                0: 0,
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                last: 10,
            ),
        ),
        // 'margin': null,
        // 'margin-x': null,
        // 'margin-y': null,
        // 'margin-top': null,
        // 'margin-end': null,
        // 'margin-bottom': null,
        // 'margin-start': null,
        // 'negative-margin': null,
        // 'negative-margin-x': null,
        // 'negative-margin-y': null,
        // 'negative-margin-top': null,
        // 'negative-margin-end': null,
        // 'negative-margin-bottom': null,
        // 'negative-margin-start': null,
        // 'padding': null,
        // 'padding-x': null,
        // 'padding-y': null,
        // 'padding-top': null,
        // 'padding-end': null,
        // 'padding-bottom': null,
        // 'padding-start': null,
        'font-family': null,
        'font-size': null,
        'font-style': null,
        'font-weight': (
            property: font-weight,
            class: fw,
            values: (
                lighter: $font-weight-lighter,
                light: $font-weight-light,
                normal: $font-weight-normal,
                medium: $font-weight-medium,
                semibold: $font-weight-semibold,
                bold: $font-weight-bold,
                bolder: $font-weight-bolder,
            ),
        ),
        'line-height': null,
        // 'text-align': null,
        // 'text-decoration': null,
        'text-transform':
            (
                property: text-transform,
                class: text,
                values: lowercase uppercase capitalize none,
            ),
        // 'white-space': null,
        'word-wrap': null,
        // 'color': null,
        'text-opacity': null,
        // 'background-color': null,
        'bg-opacity': null,
        'gradient': null,
        'user-select': null,
        'pointer-events': null,
        'rounded': null,
        'rounded-top': null,
        'rounded-end': null,
        'rounded-bottom': null,
        'rounded-start': null,
        'visibility': null,
    )
);

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}
/* stylelint-enable */
