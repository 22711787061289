@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.heroIntro {
    display: flex;
    flex-wrap: nowrap;
    background-color: $grey-light;
    color: $white;
    &::before {
        content: '';
        padding-top: 60%;
    }

    &--big::before {
        padding-top: 110%;
        @media ($smUp) {
            padding-top: 38%;
        }
        @media ($xxlUp) {
            padding-top: 35%;
        }
    }

    // VARIANTs
    &--lp {
        .heroIntroBottom {
            max-width: 52rem;
        }

        .heroIntroBackground {
            &::after {
                @include image-horizontal-gradient();
            }
        }
    }

    &--blog {
        .heroIntroBackground {
            &::after {
                @include image-gradient();
            }

            @media ($mdUp) {
                &::after {
                    height: 75%;
                }
            }
        }
    }

    &--hp {
        &::before {
            padding-top: #{63 / 229 * 100%};
        }
        .heroIntroBackground::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: rgba($black, 0.4);
        }
        .heroIntroHeading {
            max-width: none;
            line-height: 1.5;

            @media ($lgDown) {
                font-size: 2rem;
                line-height: 1.4;
            }
        }
        .heroIntroContent {
            padding-bottom: 13rem;
        }

        @media ($mdUp) {
            margin-top: 1.5rem;
        }
        @media ($fullUp) {
            margin-top: 2.5rem;
        }
    }

    // MQs
    @media ($mdUp) {
        border-radius: $border-radius-xl;
        &::before {
            padding-top: 35%;
        }

        &--blog::before {
            padding-top: 25%;
        }

        &--hp {
            &::before {
                padding-top: #{63 / 229 * 100%};
            }
        }
    }

    @media ($lgUp) {
        // VARIANTs
        &--lp .heroIntroBottom {
            max-width: 47rem;
        }
    }
    @media ($xlUp) {
        // VARIANTs
        &--lp .heroIntroContent {
            flex-direction: row;
        }
    }
    @media ($xxxlUp) {
        &--hp .heroIntroContent {
            padding-bottom: 13rem !important; /* stylelint-disable-line */
        }
    }
}
.heroIntroBackground,
.heroIntroContent {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: inherit;
}
.heroIntroBackground {
    position: relative;
    &--gradient {
        &::after {
            height: 100% !important; /* stylelint-disable-line */
            // @include image-horizontal-gradient();
            background-image: linear-gradient(270deg, rgba(33, 31, 31, 0), #1e1b1b);
        }
    }
    &::after {
        content: '';
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        pointer-events: none;
    }

    // MQs
    @media ($mdUp) {
        &::after {
            height: 75%;
        }
    }
}
.heroIntroContent {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: -100%;

    // MQs
    @media ($xxxlUp) {
        padding-right: 3.5rem !important; /* stylelint-disable-line */
        padding-bottom: 3.5rem !important; /* stylelint-disable-line */
        padding-left: 3.5rem !important; /* stylelint-disable-line */
    }
}
.heroIntroSide {
    position: relative;
    display: flex;
    flex-direction: column;

    // MQs
    @media ($xlUp) {
        flex: 1 1 auto;
    }
}
.heroIntroTop {
    position: relative;
    z-index: 1;
}
.heroIntroCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.heroIntroPromoButton {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(30px);
    }
    > * {
        position: relative;
    }
}
.heroIntroHeading {
    max-width: 50rem;

    // MQs
    @media ($lgUp) and ($xxxlDown) {
        &:global(.h1) {
            font-size: $h2-font-size;
        }
    }
    @media ($xxxlUp) {
        max-width: 40rem;
    }
}
