// Color system
$anthracite-grey: #3b3737;
$emerald-green: #1f9970;
$emerald-green-hover: #116c4e;
$fire-red: #e63d2e;
$fire-red-hover: #c23427;
$grey-dark: #807878;
$grey-light: #e0dcda;
$grey-medium: #ccbebc;
$grey-ultra-dark: #665e5e;
$grey-ultra-light: #f2f0f0;
$red-error: #cc0000;
$royal-blue: #5151f0;
$royal-blue-hover: #3b32c2;
$white: #ffffff;
$black: #000000;
$yellow: #ffc107;
$blue-light: #0dcaf0;
$green-light: #dbf0ea;
$brown: #994e0d;
$orange-light: #ffe8cd;

$primary: $fire-red;
$success: $emerald-green;
$danger: $red-error;
$warning: $yellow;
$warning-alt: $orange-light;
$info: $blue-light;
$violet: #e4e2ef;

$facebook: #1877f2;
$instagram: #833ab4;
$twitter: #1da1f2;

$ua-yellow: #ffcc00;
$ua-blue: #0066cc;

// Options
$enable-rfs: false;
$enable-negative-margins: true;

// Gradient
$gradient-image: linear-gradient(to top, #1e1b1b, rgba(#211f1f, 0));
$gradient-horizontal-image: linear-gradient(to left, rgba(#211f1f, 0), #1e1b1b);

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer * 1,
    5: $spacer * 1.25,
    6: $spacer * 1.5,
    7: $spacer * 1.75,
    8: $spacer * 2,
    9: $spacer * 2.25,
    10: $spacer * 2.5,
    11: $spacer * 2.75,
    12: $spacer * 3,
    13: $spacer * 3.25,
    14: $spacer * 3.5,
    15: $spacer * 3.75,
    16: $spacer * 4,
    17: $spacer * 4.25,
    18: $spacer * 4.5,
    19: $spacer * 4.75,
    20: $spacer * 5,
    21: $spacer * 5.25,
    22: $spacer * 5.5,
    23: $spacer * 5.75,
    24: $spacer * 6,
    25: $spacer * 6.25,
);

// Body
$body-bg: $grey-ultra-light;
$body-color: $anthracite-grey;

// Utilities maps
$utilities-text-colors: (
    'white': $white,
    'red': $fire-red,
    'green': $emerald-green,
    'blue': $royal-blue,
    'grey-dark': $grey-dark,
    'grey-light': $grey-light,
    'default': $anthracite-grey,
    'current': currentcolor,
    'warning': $warning,
);

$utilities-bg-colors: (
    'primary': $primary,
    'red': $fire-red,
    'white': $white,
    'green': $emerald-green,
    'blue': $royal-blue,
    'grey-light': $grey-light,
    'grey-ultra-light': $grey-ultra-light,
    'grey-dark': $grey-dark,
    'success': $success,
    'danger': $danger,
    'warning': $warning,
    'info': $info,
    'violet': $violet,
);

// Links
$link-decoration: none;
$link-hover-color: $fire-red-hover;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: map_get($breakpoints, 'sm'),
    md: map_get($breakpoints, 'md'),
    lg: map_get($breakpoints, 'lg'),
    xl: map_get($breakpoints, 'xl'),
    xxl: map_get($breakpoints, 'xxl'),
);

$container-max-width: 1920px;
$container-large-max-width: 1760px;
$container-narrow-max-width: 1536px;
$container-narrower-max-width: 1456px;
$container-medium-max-width: 1312px;
$container-thin-max-width: 832px;

$grid-columns: 16;
$grid-columns-mobile: 12;
$grid-gutter-width: 2rem;
$grid-gutter-width-mobile: 1.0625rem;

$container-padding-x: 10rem;
$container-padding-x-mobile: $container-padding-x * 0.2;
$container-padding-x-tablet: $container-padding-x * 0.3;

// Components
$border-width: 1px;

$border-radius: 1rem;
$border-radius-xxs: 0.25rem;
$border-radius-xs: 0.5rem;
$border-radius-sm: 0.75rem;
$border-radius-lg: 1.125rem;
$border-radius-xl: 1.375rem;

$box-shadow-custom: 0 0 30px rgba($black, 0.04);
$box-shadow-custom-lg: 0 0 30px rgba($black, 0.12);

$transition: 0.2s ease-in-out;
$transition-fade: opacity 0.2s ease-in-out;

// SVGs
$footer-accordion-button-svg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$grey-dark}' d='M18.366 8.463a1 1 0 01.078 1.327l-.078.087-5.657 5.657a1 1 0 01-1.327.078l-.087-.078-5.657-5.657a1 1 0 011.327-1.492l.087.078 4.95 4.948 4.95-4.948a1 1 0 011.327-.078l.087.078z'/></svg>");

// Typography
$font-family-sans-serif:
    'Twemoji Country Flags',
    'Manrope',
    system-ui,
    -apple-system,
    'Segoe UI',
    roboto,
    'Helvetica Neue',
    arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';

$font-size-base: 1rem;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 600;
$font-weight-bolder: 600;

$line-height-base: 1.5;

$letter-spacing: 0.0625rem;

$h1-font-size: $font-size-base * 4.5;
$h2-font-size: $font-size-base * 3.5;
$h3-font-size: $font-size-base * 2.5;
$h4-font-size: $font-size-base * 2;
$h5-font-size: $font-size-base * 1.375;
$h6-font-size: $font-size-base;
$title-font-size: $font-size-base * 6;
$subheadline-font-size: $font-size-base * 1.375;
$subheadline-lg-font-size: $font-size-base * 1.625;
$perex-font-size: $font-size-base * 1.125;
$perex-lg-font-size: $font-size-base * 1.375;
$body-font-size: $font-size-base;
$caption-font-size: $font-size-base * 0.875;
$link-perex-font-size: $font-size-base * 1.125;
$cta-font-size: $font-size-base * 0.875;
$xs-font-size: $font-size-base * 0.75;

$title-font-size-mobile: $font-size-base * 2.875;
$h1-font-size-mobile: $font-size-base * 2.5;
$h2-font-size-mobile: $font-size-base * 2;
$h3-font-size-mobile: $font-size-base * 1.75;
$h4-font-size-mobile: $font-size-base * 1.375;
$h5-font-size-mobile: $font-size-base * 1.25;
$h6-font-size-mobile: $font-size-base;
$subheadline-font-size-mobile: $font-size-base * 1.125;
$subheadline-lg-font-size-mobile: $font-size-base * 1.375;
$caption-font-size-mobile: $font-size-base * 0.8125;

$title-line-height: divide(108, 96);
$h1-line-height: divide(82, 72);
$h2-line-height: divide(68, 56);
$h3-line-height: divide(50, 40);
$h4-line-height: divide(42, 32);
$h5-line-height: divide(28, 22);
$h6-line-height: $line-height-base;
$subheadline-line-height: divide(28, 22);
$subheadline-lg-line-height: divide(32, 26);
$perex-line-height: divide(26, 18);
$perex-lg-line-height: divide(32, 22);
$body-line-height: $line-height-base;
$caption-line-height: divide(18, 14);
$link-perex-line-height: divide(24, 18);
$cta-line-height: divide(16, 14);
$xs-line-height: divide(16, 12);

$title-line-height-mobile: divide(52, 46);
$h1-line-height-mobile: divide(48, 40);
$h2-line-height-mobile: divide(42, 32);
$h3-line-height-mobile: divide(36, 28);
$h4-line-height-mobile: divide(32, 22);
$h5-line-height-mobile: divide(28, 20);
$h6-line-height-mobile: $line-height-base;
$subheadline-line-height-mobile: divide(28, 18);
$subheadline-lg-line-height-mobile: divide(32, 22);
$caption-line-height-mobile: divide(18, 13);

$headings-font-weight: $font-weight-light;

$hr-color: $grey-medium;
$hr-opacity: 1;

// Tables
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.375rem;
$table-cell-padding-x-sm: 0.375rem;

$table-bg: $white;
$table-striped-bg: $grey-ultra-light;

// Buttons + Forms
$input-btn-padding-y: 1.0625rem;
$input-btn-padding-x: 1.4375rem;
$input-btn-font-size: $font-size-base;

$input-btn-font-size-sm: $input-btn-font-size;
$input-btn-padding-y-sm: 0.4375rem;
$input-btn-padding-x-sm: 1.1875rem;

$input-btn-font-size-lg: $input-btn-font-size;
$input-btn-padding-y-lg: 1.4375rem;
$input-btn-padding-x-lg: $input-btn-padding-x;

$input-btn-border-width: $border-width;

// Buttons
$btn-variants: (
    'primary': (
        'background': $fire-red,
        'border': $fire-red,
        'color': $white,
        'hover-background': $fire-red-hover,
        'hover-border': $fire-red-hover,
        'hover-color': $white,
        'active-color': $white,
    ),
    'secondary': (
        'background': $royal-blue,
        'border': $royal-blue,
        'color': $white,
        'hover-background': $royal-blue-hover,
        'hover-border': $royal-blue-hover,
        'hover-color': $white,
        'active-color': $white,
    ),
    'light': (
        'background': $white,
        'border': $white,
        'color': $anthracite-grey,
        'hover-background': $grey-medium,
        'hover-border': $grey-medium,
        'hover-color': $black,
        'active-color': $black,
    ),
    'tag': (
        'background': transparent,
        'border': $anthracite-grey,
        'color': $anthracite-grey,
        'hover-background': $grey-medium,
        'hover-border': $grey-medium,
        'hover-color': $black,
        'active-color': $black,
    ),
    'green': (
        'background': $emerald-green,
        'border': $emerald-green,
        'color': $white,
        'hover-background': $emerald-green-hover,
        'hover-border': $emerald-green-hover,
        'hover-color': $white,
        'active-color': $white,
    ),
    'success': (
        'background': $emerald-green,
        'border': $emerald-green,
        'color': $white,
        'hover-background': $emerald-green-hover,
        'hover-border': $emerald-green-hover,
        'hover-color': $white,
        'active-color': $white,
    ),
    'danger': (
        'background': $danger,
        'border': $danger,
        'color': $white,
        'hover-background': darken($danger, 10%),
        'hover-border': darken($danger, 10%),
        'hover-color': $white,
        'active-color': $white,
    ),
    'warning': (
        'background': $warning,
        'border': $warning,
        'color': $white,
        'hover-background': darken($warning, 10%),
        'hover-border': darken($warning, 10%),
        'hover-color': $white,
        'active-color': $white,
    ),
);
$btn-outline-variants: (
    'primary-light': (
        'color': $fire-red,
        'color-hover': $white,
        'active-color': $white,
    ),
    'secondary-light': (
        'color': $royal-blue,
        'color-hover': $white,
        'active-color': $white,
    ),
    'dark': (
        'color': $grey-medium,
        'color-hover': $black,
        'active-color': $black,
    ),
    'medium': (
        'color': $grey-medium,
        'color-hover': $black,
        'active-color': $black,
    ),
    'white': (
        'color': $white,
        'color-hover': $anthracite-grey,
        'active-color': $anthracite-grey,
    ),
);

$btn-font-size-mobile: 0.875rem;

$btn-padding-y-md: 0.6875rem;
$btn-padding-x-md: 1.1875rem;
$btn-font-size-md: 0.875rem;

$btn-font-weight: $font-weight-semibold;
$btn-disabled-opacity: 0.5;

$btn-border-radius: 0.75rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-md: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

$btn-transition:
    color $transition,
    background-color $transition,
    border-color $transition,
    box-shadow $transition;

$btn-link-font-size: 0.875rem;
$btn-link-line-height: divide(16, 14);
$btn-link-padding: 0.1875rem;

// Forms
$form-label-margin-bottom: 0.375rem;
$form-label-font-size: 0.75rem;
$form-label-line-height: divide(16, 12);
$form-label-font-weight: $font-weight-medium;
$form-label-color: $grey-dark;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: 1.4375rem;
$input-font-size: 1.125rem;
$input-line-height: divide(24, 18);

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: 0.9375rem;
$input-font-size-sm: 1rem;
$input-line-height-sm: divide(24, 16);

$input-padding-y-lg: 1.3125rem;
$input-padding-x-lg: 2.0625rem;
$input-font-size-lg: 1.375rem;
$input-line-height-lg: divide(28, 22);

$input-bg: $white;
$input-disabled-bg: $input-bg;

$input-color: $anthracite-grey;
$input-border-color: $grey-medium;
$input-border-width: $input-btn-border-width;

$input-border-radius: $btn-border-radius;
$input-border-radius-sm: $input-border-radius;
$input-border-radius-lg: $input-border-radius;

$input-focus-border-color: $anthracite-grey;
$input-focus-box-shadow: none;

$input-placeholder-color: $grey-dark;

$input-height-border: $input-border-width * 2;

$input-height-sm: add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition: border-color $transition;

$form-check-input-width: 2em;
$form-check-input-width-sm: 1.75em;
$form-check-input-width-xs: 1.5em;
$form-check-min-height: $form-check-input-width;
$form-check-min-height-sm: $form-check-input-width-sm;
$form-check-min-height-xs: $form-check-input-width-xs;
$form-check-padding-start: $form-check-input-width + 1em;
$form-check-padding-start-sm: $form-check-input-width-sm + 1em;
$form-check-padding-start-xs: $form-check-input-width-xs + 1em;
$form-check-margin-bottom: 0;
$form-check-label-cursor: pointer;
$form-check-transition:
    border $transition,
    background-color $transition;

$form-check-input-active-filter: none;

$form-check-input-bg: rgba($white, 0.5);
$form-check-input-border: 1px solid $grey-medium;
$form-check-input-border-radius: 0.5em;

$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $anthracite-grey;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path fill='#{$form-check-input-checked-color}' d='M21.326 12.293a1 1 0 011.498 1.32l-.083.094-7.056 7.056a1 1 0 01-1.32.083l-.094-.083-3.978-3.978a1 1 0 011.32-1.498l.094.084 3.27 3.27 6.35-6.348z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-16 -16 32 32'><circle r='10' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-bg-color: $anthracite-grey;
$form-check-input-indeterminate-border-color: $anthracite-grey;

$form-check-font-size: 1.125em;
$form-check-font-size-sm: 0.875em;
$form-check-font-size-xs: 1em;
$form-check-line-height: divide(24, 18);
$form-check-line-height-sm: divide(16, 14);
$form-check-line-height-xs: divide(20, 16);

$form-switch-color: $anthracite-grey;
$form-switch-width: 5em;
$form-switch-height: 2.5em;
$form-switch-padding-start: $form-switch-width + 1em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-20 -20 40 40'><circle r='18' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-height;
$form-switch-transition:
    border $transition,
    background-color $transition,
    background-position $transition;

$form-switch-focus-color: $form-switch-color;
$form-switch-focus-bg-image: $form-switch-bg-image;

$form-switch-checked-color: $emerald-green;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-20 -20 40 40'><circle r='18' fill='#{$form-switch-checked-color}'/></svg>");

$input-group-addon-bg: $input-bg;

$form-select-padding-x: $input-padding-x;
$form-select-indicator-padding: $form-select-padding-x * 2 + 0.625rem;
$form-select-disabled-bg: $input-disabled-bg;
$form-select-bg-size: 0.625rem 0.375rem;
$form-select-indicator-color: $grey-dark;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'><path fill='#{$form-select-indicator-color}' d='M5 6L0 0h10z'/></svg>");
$form-select-indicator-color-blank: $anthracite-grey;
$form-select-indicator-blank: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'><path fill='#{$form-select-indicator-color-blank}' d='M5 6L0 0h10z'/></svg>");

$form-select-focus-box-shadow: $input-focus-box-shadow;

$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-indicator-padding-sm: $form-select-padding-x-sm * 2 + 0.625rem;
$form-select-line-height-sm: $input-line-height-sm;
$form-select-bg-position-sm: right $form-select-padding-x-sm center;

$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-indicator-padding-lg: $form-select-padding-x-lg * 2 + 0.625rem;
$form-select-line-height-lg: $input-line-height-lg;
$form-select-bg-position-lg: right $form-select-padding-x-lg center;

$form-floating-label-ratio: divide($form-label-font-size, $input-font-size);
$form-floating-label-transform: translateY($form-label-margin-bottom - (0.0625rem * 0.5)) scale($form-floating-label-ratio)
    translateY(-100%) translateX(-($input-padding-x + 0.0625rem));
$form-floating-transition: transform $transition;

$form-floating-label-ratio-sm: divide($form-label-font-size, $input-font-size-sm);
$form-floating-label-transform-sm: translateY((0.0625rem * 0.5)) scale($form-floating-label-ratio-sm) translateY(-100%)
    translateX(-($input-padding-x-sm + 0.0625rem));

$form-floating-label-ratio-lg: divide($form-label-font-size, $input-font-size-lg);
$form-floating-label-transform-lg: translateY($form-label-margin-bottom - (0.0625rem * 0.5)) scale($form-floating-label-ratio-lg)
    translateY(-100%) translateX(-($input-padding-x-lg + 0.0625rem));

$form-tag-close-color: $anthracite-grey;
$form-tag-close: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-tag-close-color}' d='M8.446 7.397l.084.073L12 10.939l3.47-3.47a.75.75 0 011.133.977l-.073.084L13.061 12l3.47 3.47a.75.75 0 01-.977 1.133l-.084-.073L12 13.061l-3.47 3.47a.75.75 0 01-1.133-.977l.073-.084L10.939 12l-3.47-3.47a.75.75 0 01.875-1.197l.102.064z'/></svg>");

// Form validation
$form-feedback-margin-top: $form-label-margin-bottom;
$form-feedback-font-size: $form-label-font-size;
$form-feedback-line-height: $form-label-line-height;
$form-feedback-font-weight: $font-weight-medium;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-feedback-icon-valid-color}' d='M19.47 5.97a.75.75 0 011.133.976l-.073.084-11 11a.75.75 0 01-.976.073l-.084-.073-5-5a.75.75 0 01.976-1.133l.084.073L9 16.439l10.47-10.47z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-feedback-icon-invalid-color}' d='M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm.004 1.499l-.248.005c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.99-8.003l-.252-.004zm-.005 11.501l.117.007a1 1 0 11-.226-.001l.109-.006zM12 6.75a.75.75 0 01.743.648l.007.102v5a.75.75 0 01-1.493.102l-.007-.102v-5a.75.75 0 01.75-.75z'/></svg>");

// Navs
$nav-link-padding-y: 1.0625rem;
$nav-link-padding-x: 1.25rem;
$nav-link-font-size: 1.125rem;
$nav-link-color: $grey-dark;
$nav-link-hover-color: $nav-link-color;

// Z-index master list
$zindex-spinner: 1090;

// Dropdowns
$dropdown-min-width: 12.5rem;
$dropdown-padding-x: 1.25rem;
$dropdown-padding-y: 0.75rem;
$dropdown-color: $body-color;
$dropdown-border-radius: $border-radius-sm;
$dropdown-inner-border-radius: $dropdown-border-radius;
$dropdown-divider-bg: $grey-medium;
$dropdown-divider-margin-y: 0.5rem;
$dropdown-box-shadow: $box-shadow-custom-lg;

$dropdown-link-color: $dropdown-color;
$dropdown-link-hover-color: $link-hover-color;

$dropdown-link-active-color: $link-hover-color;

$dropdown-link-disabled-color: rgba($body-color, 0.5);

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: $dropdown-padding-x;

// Toasts
$toast-border-radius: $border-radius-xs;
$toast-spacing: 1rem;

// Badges
$badge-font-size: 0.875rem;
$badge-padding-y: 0.1875rem;
$badge-padding-x: 0.1875rem;
$badge-border-radius: 0.625rem;

// Modals
$modal-inner-padding: 2.5rem;

$modal-footer-margin-between: 0.75rem;

$modal-title-line-height: $subheadline-line-height;

$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 1.125rem;

$modal-backdrop-bg: $anthracite-grey;
$modal-backdrop-opacity: 0.4;
$modal-header-border-color: $grey-ultra-light;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: 1px;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 3.75rem;
$modal-footer-padding-y: $modal-header-padding-y;
$modal-footer-padding-x: $modal-footer-padding-y;

$modal-xl: 928px;

// Progress bars
$progress-height: 0.1875rem;
$progress-bg: $grey-medium;
$progress-border-radius: 0;

// Breadcrumbs
$breadcrumb-font-size: 0.875rem;
$breadcrumb-item-padding-x: 0.625rem;
$breadcrumb-margin-bottom: -0.25rem;
$breadcrumb-divider-color: $fire-red;
$breadcrumb-active-color: $grey-dark;
$breadcrumb-divider: '~';

// Spinners
$spinner-width: 3rem;
$spinner-height: $spinner-width;

// Close
$btn-close-width: 2rem;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.5rem;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $anthracite-grey;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$btn-close-color}' d='M8.446 7.397l.084.073L12 10.939l3.47-3.47a.75.75 0 011.133.977l-.073.084L13.061 12l3.47 3.47a.75.75 0 01-.977 1.133l-.084-.073L12 13.061l-3.47 3.47a.75.75 0 01-1.133-.977l.073-.084L10.939 12l-3.47-3.47a.75.75 0 01.875-1.197l.102.064z'/></svg>");
$btn-close-opacity: 0.5;
$btn-close-hover-opacity: 1;

// Offcanvas
$offcanvas-horizontal-width: 33rem;

// FilterSidebar
$filter-sidebar-header-button-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'><path fill='#{$anthracite-grey}' d='M5 6L0 0h10z'/></svg>");

// MessagesLayout
$messages-layout-gutter: 1.5rem;

// CalendarLayout
$calendar-layout-gutter: 2.5rem;

// Calendar
$calendar-slot-blank-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$anthracite-grey}' d='M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm0 1.5l-.244.004c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.994-8.002L12 3.75zm0 3.5a.75.75 0 01.743.648L12.75 8v3.25H16a.75.75 0 01.102 1.493L16 12.75h-3.25V16a.75.75 0 01-1.493.102L11.25 16v-3.25H8a.75.75 0 01-.102-1.493L8 11.25h3.25V8a.75.75 0 01.75-.75z'/></svg>");

// Alerts
$alert-variants: (
    'warning-alt': (
        'background': $warning-alt,
        'border': $warning-alt,
        'color': $black,
    ),
);
