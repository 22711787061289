/* stylelint-disable */
html.fslightbox-open {
	overflow: visible !important;
	body {
		overflow: hidden;
	}
}
/* stylelint-enable */
.fslightbox-nav {
	z-index: 1;
}
